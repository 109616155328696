<template>
  <div style="width: 100%;height: 100%">
    <div style="font-size: 7px;width: calc(100% - 10px);height: calc(100% - 10px);overflow-y: hidden;" @mouseover="mouseAction(0)" @mouseleave="mouseAction(1)">
      <div style="width: 100%;display: flex;color: #fff;justify-content: space-around">
        <div style="width: 16.5%;height: 20px;line-height: 20px;text-align: center" >服务项目</div>
        <div style="width: 16.5%;height: 20px;line-height: 20px;text-align: center" >服务对象</div>
        <div style="width: 16.5%;height: 20px;line-height: 20px;text-align: center" >服务者</div>
        <div style="width: 16.5%;height: 20px;line-height: 20px;text-align: center" >工单时长</div>
        <div style="width: 16.5%;height: 20px;line-height: 20px;text-align: center" >开始时间</div>
        <div style="width: 16.5%;height: 20px;line-height: 20px;text-align: center" >完成时间</div>
      </div>
      <div sstyle="height: calc(100% - 24px);overflow-y: hidden;" id="mainTable">
        <div :class="{'animate-up-small-table':animateUp}" style="width: 100%;display: flex;color: #fff;justify-content: space-around;" v-for="(item,index) in dateDaily" :key="index">
          <div style="width: 16.5%;height: 20px;line-height: 20px;text-align: center">{{item.name}}</div>
          <div style="width: 16.5%;height: 20px;line-height: 20px;text-align: center">{{item.dqjss}}</div>
          <div style="width: 16.5%;height: 20px;line-height: 20px;text-align: center">{{item.dqxys}}</div>
          <div style="width: 16.5%;height: 20px;line-height: 20px;text-align: center">{{item.ljzsrs}}</div>
          <div style="width: 16.5%;height: 20px;line-height: 20px;text-align: center">{{item.ljkbsl}}</div>
          <div style="width: 16.5%;height: 20px;line-height: 20px;text-align: center">{{item.ljbkrs}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "daping3table",
  props:{
    dateDaily: Array,
    animateUp:Boolean,
  },
  data() {
    // return {
    //   columns1: [
    //     {
    //       title: '服务项目',
    //       key: 'name',
    //       align: 'center',
    //     },
    //     {
    //       title: '服务对象',
    //       key: 'dqjss',
    //       align: 'center',
    //     },
    //     {
    //       title: '服务者',
    //       key: 'dqxys',
    //       align: 'center',
    //     },
    //     {
    //       title: '工单时长',
    //       key: 'ljzsrs',
    //       align: 'center',
    //     },
    //     {
    //       title: '开始时间',
    //       key: 'ljkbsl',
    //       align: 'center',
    //     },
    //     {
    //       title: '完成时间',
    //       key: 'ljbkrs',
    //       align: 'center',
    //     },
    //   ],
    //   data1: [
    //     {
    //       name: '产科',
    //       dqjss: '371',
    //       dqxys: '1402.87',
    //       ljzsrs: '1.48',
    //       ljkbsl: '0.87',
    //       ljbkrs: '1.06'
    //     },
    //     {
    //       name: '妇科',
    //       dqjss: '392',
    //       dqxys: '1429.61',
    //       ljzsrs: '1.51',
    //       ljkbsl: '0.98',
    //       ljbkrs: '0.95'
    //     },
    //
    //     {
    //       name: '呼吸内科',
    //       dqjss: '231',
    //       dqxys: '1185.47',
    //       ljzsrs: '1.15',
    //       ljkbsl: '1.06',
    //       ljbkrs: '1.07'
    //     },
    //
    //     {
    //       name: '儿科',
    //       dqjss: '122',
    //       dqxys: '1209.32',
    //       ljzsrs: '1.05',
    //       ljkbsl: '1.06',
    //       ljbkrs: '1.06'
    //     },
    //     {
    //       name: '精神科',
    //       dqjss: '265',
    //       dqxys: '1008.32',
    //       ljzsrs: '0.95',
    //       ljkbsl: '0.95',
    //       ljbkrs: '0.97'
    //     },
    //     {
    //       name: '放射科',
    //       dqjss: '285',
    //       dqxys: '1074.83',
    //       ljzsrs: '1.12',
    //       ljkbsl: '0.90',
    //       ljbkrs: '1.04'
    //     },
    //     {
    //       name: '检验科',
    //       dqjss: '306',
    //       dqxys: '1274.58',
    //       ljzsrs: '1.24',
    //       ljkbsl: '0.96',
    //       ljbkrs: '0.96'
    //     },
    //     {
    //       name: 'ICU',
    //       dqjss: '135',
    //       dqxys: '793.17',
    //       ljzsrs: '0.89',
    //       ljkbsl: '0.84',
    //       ljbkrs: '0.93'
    //     },
    //     {
    //       name: '心电图室',
    //       dqjss: '159',
    //       dqxys: '889.48',
    //       ljzsrs: '0.99',
    //       ljkbsl: '0.97',
    //       ljbkrs: '1.15'
    //     },
    //     {
    //       name: '透析室',
    //       dqjss: '205',
    //       dqxys: '947.85',
    //       ljzsrs: '1.07',
    //       ljkbsl: '1.12',
    //       ljbkrs: '0.94'
    //     },
    //
    //   ]
    // }
  },
  methods: {
    mouseAction(flag) {
      // if(flag === 1) {
      //   this.timer1 = setInterval(this.scrollAnimate, 1500);
      // } else {
      //   clearInterval(this.timer1)
      // }
      this.$parent.mouseAction(flag)
    },
  }

}
</script>

<style>
.ivu-table {
  background: none;
  font-size: 14px;
}

.ivu-table:before {
  background: none;
}

.ivu-table th {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  border: none;

}

.ivu-table .demo-table-info-row td {
  background: none;
  color: white;
  border: none;

}


</style>
