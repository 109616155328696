<template>
  <div class="echart" id="echart-nandinggeertu" :style="{ float: 'left', width: '100%', height: '100%' }"></div>
</template>

<script>
import echarts from 'echarts';
export default {
  name: 'nandinggeertu',
  data(){
    return {

    }
  },
  methods: {
    initChart() {
      let getchart = echarts.init(document.getElementById('echart-nandinggeertu'));
      var periodPieOption =  {
        color:['#91C7AE','#63869E'],
        toolbox: {
          show: true,
        },
        series: [
          {
            type: 'pie',
            radius: [0,75],
            center: ['50%', '55%'],
            itemStyle: {
              borderRadius: 30,
              mormal:{
                color:['red','green']
              },
            },
            data: [
              { value: 200, name: '缺口占比20%' },
              { value: 800, name: '存量满足占比80%' },
              // { value: 22, name: '出院人次' },
              // { value: 22, name: '门诊人次' },
              // { value: 22, name: '在院人数' },
              // { value: 22, name: '手术例数' },
              // { value: 22, name: '门诊均次费用（元）' },
              // { value: 22, name: '住院均次费用（元）' },
              // { value: 22, name: '住院收入（万元）'},
              // { value: 22, name: '平均住院日' },
              // { value: 22, name: '床位使用率'},
              // { value: 22, name: '药占比'},
              // { value: 22, name: '耗材占比'},
              // { value: 22, name: '检查检验占比'}
            ]
          }
        ]
      };

      getchart.setOption(periodPieOption);
      //随着屏幕大小调节图表
      window.addEventListener('resize', () => {
        getchart.resize();
      });
    },
  },
  mounted() {
    this.initChart();
  },
};
</script>

<style scoped></style>
