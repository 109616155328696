<template>
  <div class="head">
    <!--  标题  -->
    <Row style="width: 100%;height: 10%;margin-top: 15px">
      <Col span="24">
        <div class="bt" style="height: 60px;">
          <img :src="bt" width="100%" height="50px"/>
          <p class="bt1">安康市智慧康养云服务平台指挥监控大屏</p>
        </div>
      </Col>
    </Row>
    <!--  内容  -->
    <Row :gutter="17" style="width: 100%;height: 90%">

      <!--   第一列内容   -->
      <Col span="6" style="height: 100%">
        <!--    城市规划养老床位缺口      -->
        <Row style="height: 5%;">
          <div class="dataStatistics">
            <p style="color: rgb(255,255,255);font-size: 17px">城市规划养老床位缺口</p>
          </div>
        </Row>
        <!--饼图-->
        <img :src="content" width="96%" height="43%" style="position: absolute; margin-top: 4%"/>
        <Row style="height: 45%;">
          <nandinggeertu></nandinggeertu>
        </Row>
        <!--    城市规划服务人员缺口      -->
        <Row style="height: 5%;margin-top: 2%">
          <div class="TeachingSituation">
            <p style="color: rgb(255,255,255);font-size: 17px">城市规划服务人员缺口</p>
          </div>
        </Row>
        <!--饼图-->
        <img :src="content" width="96%" height="41.4%"  style="position: absolute; margin-top: 1%"/>
        <Row style="height: 45%;margin-top: 3%">
          <nandinggeertu2></nandinggeertu2>
        </Row>
      </Col>
      <!--  第二列内容    -->
      <Col span="12" style="height: 100%">

        <!--    工单人次   -->
        <Row style="height: 12%;margin-top: 1%">
          <!--            小屏1-->
          <div style="float:left;background-color:#041330;width: 23%;height: 100%; margin-left:1%; margin-right: 1%;">
            <div style="width: 100%;height: 25%;margin-top: 1%">
              <p style="text-align: center;color:white;height:25px; font-size: 17px; background-color: #001a4d">
                机构服务人次/年</p>
            </div>
            <div style="width: 90%;height: 60%;margin-top: 1%">
              <div style="font-size: 25px;width: 55%;float: left;margin-top: 15px">19333</div>
              <div style="font-size: 15px;color: red;width: 40%;float: left;margin-top: 25px">15%▼</div>
            </div>
          </div>
          <!--          小屏2-->
          <div style="float:left;background-color:#041330;width: 23%;height: 100%; margin-left:1%; margin-right: 1%;">
            <div style="width: 100%;height: 25%;margin-top: 1%">
              <p style="text-align: center;color:white;height:25px; font-size: 17px; background-color: #001A4D">
                社区服务人次/年</p>
            </div>
            <div style="width: 90%;height: 60%;margin-top: 1%">
              <div style="font-size: 25px;width: 55%;float: left;margin-top: 15px">19833</div>
              <div style="font-size: 15px;color: green;width: 40%;float: left;margin-top: 25px">15%▲</div>
            </div>
          </div>
          <!--            小屏3-->
          <div style="float:left;background-color:#041330;width: 23%;height: 100%; margin-left:1%; margin-right: 1%;">
            <div style="width: 100%;height: 25%;margin-top: 1%">
              <p style="text-align: center;color:white;height:25px; font-size: 17px; background-color: #001A4D">
                居家服务人次/年</p>
            </div>
            <div style="width: 90%;height: 60%;margin-top: 1%">
              <div style="font-size: 25px;width: 55%;float: left;margin-top: 15px">20051</div>
              <div style="font-size: 15px;color: green;width: 40%;float: left;margin-top: 25px">25%▲</div>
            </div>
          </div>
          <!--          小屏4-->
          <div style="float:left;background-color:#041330;width: 23%;height: 100%; margin-left:1%; margin-right: 1%;">
            <div style="width: 100%;height: 25%;margin-top: 1%">
              <p style="text-align: center;color: white;height:25px; font-size: 17px; background-color: #001A4D">
                服务投诉人次/年</p>
            </div>
            <div style="width: 90%;height: 60%;margin-top: 1%">
              <div style="font-size: 25px;width: 55%;float: left;margin-top: 15px">13253</div>
              <div style="font-size: 15px;color: red;width: 40%;float: left;margin-top: 25px">15%▼</div>
            </div>
          </div>
        </Row>
        <Row style="height: 36%;margin-top: 1%">
          <div class="trainingSituation1">
            <img :src="content" width="100%" height="100%"/>
            <div class="trainingSituation2">
              <yibiaopan1></yibiaopan1>
              <yibiaopan2></yibiaopan2>
              <yibiaopan3></yibiaopan3>
            </div>
            <div class="trainingSituation3">
              <p>工单人次/日</p>
              <P>正常工单人次/日</P>
              <p>异常工单人次</p>
            </div>
          </div>
        </Row>
        <!--    每日工单流水信息    -->
        <Row style="height: 5%;margin-top: 1%">
          <div class="tendency">
            <p style="color: rgb(255,255,255);font-size: 17px">每日工单流水信息</p>
          </div>
        </Row>

        <Row style="height: 41%;margin-top: 1%">
          <div class="tendency1">
            <img :src="content" width="100%" height="100%"/>
            <!--              表格-->
            <div class="tendency2">
              <div style="width: calc(100% - 10px);height: calc(100%- 10px);overflow-y: hidden;" @mouseover="mouseAction(0)" @mouseleave="mouseAction(1)">
                <div style="width: 100%;display: flex;color: #fff;justify-content: space-around">
                  <div style="width: 12.5%;height: 40px;line-height: 40px;text-align: center">服务项目</div>
                  <div style="width: 12.5%;height: 40px;line-height: 40px;text-align: center">服务对象</div>
                  <div style="width: 12.5%;height: 40px;line-height: 40px;text-align: center">服务者</div>
                  <div style="width: 12.5%;height: 40px;line-height: 40px;text-align: center">工单时长/分钟</div>
                  <div style="width: 12.5%;height: 40px;line-height: 40px;text-align: center">开始时间</div>
                  <div style="width: 12.5%;height: 40px;line-height: 40px;text-align: center">完成时间</div>
                </div>
                <div style="height: calc(100% - 40px);overflow-y: hidden" id="mainTable">
                  <div :class="{ 'animate-up':animateUp}" style="width: 100%;display: flex;color: #fff;justify-content: space-around;" v-for="(item,index) in dateDaily" :key="index">
                    <div style="width: 12.5%;height: 40px;line-height: 40px;text-align: center">{{item.name}}</div>
                    <div style="width: 12.5%;height: 40px;line-height: 40px;text-align: center">{{item.dqjss}}</div>
                    <div style="width: 12.5%;height: 40px;line-height: 40px;text-align: center">{{item.dqxys}}</div>
                    <div style="width: 12.5%;height: 40px;line-height: 40px;text-align: center">{{item.ljzsrs}}</div>
                    <div style="width: 12.5%;height: 40px;line-height: 40px;text-align: center">{{item.ljkbsl}}</div>
                    <div style="width: 12.5%;height: 40px;line-height: 40px;text-align: center">{{item.ljbkrs}}</div>
                  </div>
                </div>
              </div>
            </div>
            <!--            test  表格-->
          </div>
        </Row>
      </Col>
      <!--   第三列内容   -->
      <Col span="6" style="height: 100%;">

        <!--   居家服务趋势/年   -->
        <Row style="height: 24%;margin-top: 1%">
          <div class="percentPass1">
            <img :src="content" width="100%" height="100%"/>
            <!--   趋势1   -->
            <div class="currentPeriod">
              <qushi1></qushi1>
            </div>

          </div>
        </Row>
        <!--社区服务趋势/年-->
        <Row style="height: 24%;margin-top: 1%">
          <div class="SchoolTrends1">
            <img :src="content" width="100%" height="98%"/>
            <div class="SchoolTrends2">
              <qushi2></qushi2>
            </div>
          </div>
        </Row>
        <!--        机构服务趋势-->

        <Row style="height: 24%;margin-top: 1%">
          <div class="trainingSituationRadar1">
            <img :src="content" width="100%" height="100%"/>
            <div class="trainingSituationRadar2">
              <qushi3></qushi3>
            </div>
          </div>
        </Row>
        <!--服务投诉趋势-->
        <Row style="height: 24%;margin-top: 1%">
          <div class="SchoolTrends1">
            <img :src="content" width="100%" height="98%"/>
            <div class="SchoolTrends2">
              <qushi4></qushi4>
            </div>
          </div>
        </Row>
      </Col>
    </Row>
  </div>
</template>

<script>
import bt from '@/static/img/标题.png'
import content from '@/static/img/content.png'
import admissionsPeople from '@/static/img/教师1.png'
import declineBar from '@/static/img/declineBar.png'
import riseBar from '@/static/img/riseBar.png'
import ClassNumber from '@/static/img/ClassNumber.png'
import staying from '@/static/img/staying.png'
import ClassHoursPerCapita from '@/static/img/ClassHoursPerCapita.png'
import TeachingSituationBar from "@/view/trainingSituationOrg/TeachingSituationBar";
import SchoolTrendsLine from "@/view/trainingSituationOrg/SchoolTrendsLine";
import currentPeriodGauge from "@/view/trainingSituationOrg/currentPeriodGauge";
import currentYearGauge from "@/view/trainingSituationOrg/currentYearGauge";
import lastYearGauge from "@/view/trainingSituationOrg/lastYearGauge";
import periodPie from "@/view/trainingSituationOrg/periodPie";
import cultivateRadar from "@/view/trainingSituationOrg/cultivateRadar";
import BITable from "@/view/trainingSituationOrg/trainingSituationOrgTable";
import Nandinggeertu from "./nandinggeertu";
import Nandinggeertu2 from "./nandinggeertu2";
import yibiaopan1 from "./yibiaopan1";
import yibiaopan2 from "./yibiaopan2";
import yibiaopan3 from "./yibiaopan3";
import Daping3table from "./daping3table";
import Qushi1 from "./qushi1";
import Qushi2 from "./qushi2";
import Qushi3 from "./qushi3";
import Qushi4 from "./qushi4";


export default {
  name: "zhihuijiankongdaping3",
  components: {
    Qushi4,
    Qushi3,
    Qushi2,
    Qushi1,
    Daping3table,
    yibiaopan1,
    yibiaopan2,
    yibiaopan3,
    Nandinggeertu2,
    Nandinggeertu,
    TeachingSituationBar,
    SchoolTrendsLine, currentPeriodGauge, currentYearGauge, lastYearGauge, periodPie, cultivateRadar, BITable
  },
  data() {
    return {
      bt: bt, //标题图片
      content: content, //内容图片
      admissionsPeople: admissionsPeople, //招生人次
      declineBar: declineBar, //下降柱状图
      riseBar: riseBar, //上升柱状图
      ClassNumber: ClassNumber, //开班数量图标
      staying: staying, //报考人次图标
      ClassHoursPerCapita: ClassHoursPerCapita, //人均学时图标
      animateUp: false,
      height: window.innerHeight + 'px',
      columns1: [
        {
          title: '服务项目',
          key: 'name',
          align: 'center',
        },
        {
          title: '服务对象',
          key: 'dqjss',
          align: 'center',
        },
        {
          title: '服务者',
          key: 'dqxys',
          align: 'center',
        },
        {
          title: '工单时长',
          key: 'ljzsrs',
          align: 'center',
        },
        {
          title: '开始时间',
          key: 'ljkbsl',
          align: 'center',
        },
        {
          title: '完成时间',
          key: 'ljbkrs',
          align: 'center',
        },
      ],
      dateDaily: [
        {
          name: '晨间护理',
          dqjss: '张三',
          dqxys: '朱方方',
          ljzsrs: '48',
          ljkbsl: '6:00',
          ljbkrs: '6:48'
        },
        {
          name: '晚间护理',
          dqjss: '刑言心',
          dqxys: '翠偲偲',
          ljzsrs: '50',
          ljkbsl: '18:00',
          ljbkrs: '18:50'
        },

        {
          name: '卧位护理',
          dqjss: '郏幻梅',
          dqxys: '骆德佑',
          ljzsrs: '80',
          ljkbsl: '7:10',
          ljbkrs: '9:30'
        },

        {
          name: '排泄护理',
          dqjss: '靖雅艳',
          dqxys: '许运华',
          ljzsrs: '20',
          ljkbsl: '10:00',
          ljbkrs: '10:20'
        },
        {
          name: '其他护理',
          dqjss: '冯鸿朗',
          dqxys: '宦寒松',
          ljzsrs: '60',
          ljkbsl: '14:00',
          ljbkrs: '15:00'
        },
        {
          name: '患者安全管理',
          dqjss: '明依瑶',
          dqxys: '旅沛容',
          ljzsrs: '40',
          ljkbsl: '16:00',
          ljbkrs: '16:40'
        },


      ],
    }
  },
  watch: {
    value(newValue, oldValue) {
      let allWidth = parseInt(this.windowWidth) + parseInt(this.pwidth[0]);
      if (newValue <= -allWidth) {
        this.$refs.cmdlist.style.marginLeft = this.windowWidth + "px";
        this.value = 0;
      }
    },
  },
  methods: {
    mouseAction(flag) {
      if(flag === 1) {
        this.timer1 = setInterval(this.scrollAnimate, 1500);
      } else {
        clearInterval(this.timer1)
      }
    },
    clickCommend(e) {

    },
    scrollAnimate() {
      // 滚动到顶部
      let table = document.getElementById('mainTable');
      table.scrollTop = 0;
      this.animateUp = true;
      this.timerS = setTimeout(() => {
        this.dateDaily.push(this.dateDaily[0]);
        this.dateDaily.shift();
        this.animateUp = false
      }, 500)
    },
    testForData(num) {
      for(let i=1;i<num;i++) {
        this.dateDaily.push()
      }
    },

  },
  destroyed() {
    clearInterval(this.timer1)
  },
  mounted() {
    this.testForData(30)
    this.timer1 = setInterval(this.scrollAnimate, 1500);
    window.addEventListener('resize', this.calcTableSize); //注册监听器
    this.calcTableSize();//页面创建时先调用一次
    let that = this;
    setTimeout(()=> {
      that.$forceUpdate()
    },1800);

  }
}

</script>

<style scoped>
/*页面背景渐变色*/
.head {
  background: radial-gradient(ellipse, #00102E 0, #000B27 100%);
  height: 100%;
  _min-height:200px;
  overflow: hidden;
}

/*标题*/
.bt {
  position: relative; /*相対定位*/
  display: flex;
  justify-content: center; /* 水平居中 */
}

.bt1 {
  position: absolute; /*絶対定位*/
  top: 5%;
  left: 36%;
  color: rgb(0, 255, 255);
  font-size: 20px;

}

/*课时数量*/
.dataStatistics {
  width: 98%;
  height: 5%;
  margin-top: 2%;
  background-color: rgb(0, 26, 77);
  text-align: center;
  line-height: 40px;
  position: absolute; /*絶対定位*/
}
.TeachingSituation {
  width: 96%;
  height: 5%;
  background-color: rgb(0, 26, 77);
  text-align: center;
  line-height: 40px;
  position: absolute; /*絶対定位*/
}

.trainingSituation1 {
  width: 100%;
  height: 100%;
  position: relative; /*相対定位*/
}

.trainingSituation2 {
  width: 98%;
  height: 90%;
  position: absolute; /*絶対定位*/
  display: flex;
  justify-content: flex-start;
  top: 9%;
}

.trainingSituation3 {
  width: 100%;
  height: 10%;
  position: absolute; /*絶対定位*/
  display: flex;
  justify-content: space-around;
  top: 85%;
  color: white;

}

/*招生,报考,结业趋势*/
.tendency {
  width: 100%;
  height: 100%;
  background-color: rgb(0, 26, 77);
  text-align: center;
  line-height: 40px;
}

.tendency1 {
  width: 100%;
  height: 100%;
  position: relative; /*相対定位*/
}

.tendency2 {
  width: 95%;
  height: 80%;
  position: absolute; /*絶対定位*/
  top: 10%;
  bottom: 5%;
  left: 2%;
}


/*合格率统计*/
.percentPass1 {
  width: 100%;
  height: 100%;
  position: relative; /*相対定位*/
}

/*居家服务趋势*/
.currentPeriod {
  width: 96%;
  height: 90%;
  position: absolute; /*絶対定位*/
  bottom: 5%;
  left: 3%;
}

.SchoolTrends1 {
  width: 100%;
  height: 100%;
  position: relative; /*相対定位*/

}

.SchoolTrends2 {
  width: 92%;
  height: 89%;
  position: absolute; /*絶対定位*/
  bottom: 5%;
  left: 2%;

}


.trainingSituationRadar1 {
  width: 100%;
  height: 100%;
  position: relative; /*相対定位*/
}

.trainingSituationRadar2 {
  width: 94%;
  height: 88%;
  position: absolute; /*絶対定位*/
  bottom: 5%;
  left: 2%;
}

/*表单*/
.list-child {
  display: flex;
  width: 95%;
  justify-content: space-between;
  margin-bottom: 12px;
  font-size: 14px;
  color: white;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
}
</style>