<template>
  <div class="echart" id="echart-yibiaopan1" :style="{ float: 'left', width: '100%', height: '100%' }"></div>
</template>

<script>
import echarts from 'echarts';
export default {
  name: 'yibiaopan1',
  data(){
    return {

    }
  },
  methods: {
    initChart() {
      let getchart = echarts.init(document.getElementById('echart-yibiaopan1'));
      var periodPieOption =  {

        series: [
          {
            type: 'gauge',
            center: ['50%', '42%'],
            progress: {
              show: true,
              width: 12
            },
            axisLine: {
              lineStyle: {
                width: 13
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              length: 0,
              lineStyle: {
                width: 2,
                color: '#999'
              }
            },
            axisLabel: {
              distance: 16,
              color: '#567780',
              fontSize: 10
            },
            anchor: {
              show: true,
              showAbove: true,
              size: 1,
              itemStyle: {
                borderWidth: 10
              }
            },
            title: {
              show: false
            },
            detail: {
              valueAnimation: true,
              fontSize: 15,
              offsetCenter: [0, '90%']
            },
            data: [
              {
                value: 732
              }
            ],
            max:1000
          }
        ]
      };

      getchart.setOption(periodPieOption);
      //随着屏幕大小调节图表
      window.addEventListener('resize', () => {
        getchart.resize();
      });
    },
  },
  mounted() {
    this.initChart();
  },
};
</script>

<style scoped></style>
