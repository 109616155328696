<template>
  <div class="echart" id="echart-qushi3" :style="{ float: 'left', width: '100%', height: '100%' }"></div>
</template>

<script>
import echarts from 'echarts';
export default {
  name: 'qushi3',
  data(){
    return {

    }
  },
  methods: {
    initChart() {
      let getchart = echarts.init(document.getElementById('echart-qushi3'));
      var periodPieOption =  {
        color:['#4282F4'],
        title:{
          text:"机构服务趋势/年",
          textStyle:{
            color:'white',
            fontSize:15,
          },
        },
        xAxis: {
          data: ['2005', '2006', '2007', '2008', '2009', '2010', '2011','2012','2013','2014','2015','2016',],
          axisLabel:{
            interval:0,
            rotate:45,
            fontSize: 9,
            color:'white',
          }
        },
        yAxis: {
          type: 'value',
          min:50,
          max:200,
          axisLabel:{
            color: 'white',
          },
          interval:60
        },
        series: [
          {
            data: [160, 190, 160, 190, 160, 190, 160,190,160,190,160,190],
            type: 'bar'
          }
        ]
      };

      getchart.setOption(periodPieOption);
      //随着屏幕大小调节图表
      window.addEventListener('resize', () => {
        getchart.resize();
      });
    },
  },
  mounted() {
    this.initChart();
  },
};
</script>

<style scoped></style>
